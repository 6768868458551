module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: 'CodeCreamer', // Navigation and Site Title
  siteTitleAlt: 'A Personal Blog by Jack', // Alternative Site title for SEO
  siteTitleShort: 'A Personal Blog', // short_name for manifest
  siteUrl: 'https://blog.jacklaurence.net', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription:
    'A blog about mostly about code and everything I find interesting.',
  siteKeywords: ['personal blog', 'blog', 'code', 'javascript', 'react'],
  author: 'Jack Garay', // Author for schemaORGJSONLD
  authorPhoto: './photo.jpg',
  organization: 'Jack Laurence',
  bio: 'I read then I write.',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@lawrencetecho', // Twitter Username
  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-77657080-2',

  // Manifest and Progress color
  themeColor: '#fafafa',
  backgroundColor: '#2b2e3c',

  // Social component
  twitter: 'https://twitter.com/lawrencetecho',
  twitterHandle: '@lawrencetecho',
  github: 'https://github.com/jacklaurencegaray',
  linkedin: 'https://linkedin.com/in/jacklaurence',
}
